import React, { useEffect } from "react";
import style from "./style.module.scss";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Loader,
} from "../../components";
import { setDataLoading } from "../../store/slices/admin";
import {
  clearAdminEigentuemerwechselItem,
  getAdminEigentuemerwechselItemById,
} from "../../store/slices/eigentuemerwechsel";
import { statuses } from "../AdminSperrmuelanmeldungen";

const payerTypes: { [key: string]: string } = {
  Eigentümer: "Eigentümer",
  abweichendenZahlungsempfänder: "Abweichender Rechnungsempfänger",
};

const AdminEigentuemerwechselById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isDataLoading, eigentuemerwechselItem } = useAppSelector(
    (state) => state.eigentuemerwechsel.admin
  );

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminEigentuemerwechselItemById(itemId));
    }
    return () => {
      dispatch(clearAdminEigentuemerwechselItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  if (!eigentuemerwechselItem) {
    return null;
  }

  const items = [
    {
      title: "Angaben zum Objekt",
      items: [
        {
          rowTitle: "Objektnummer",
          rowInfo: eigentuemerwechselItem?.objectId || "",
        },
        {
          rowTitle: "Straße",
          rowInfo: eigentuemerwechselItem?.strasse || "",
        },
        {
          rowTitle: "Haunummer",
          rowInfo: `${eigentuemerwechselItem?.hausnr}
                ${
                  eigentuemerwechselItem?.hausnrzusatz
                    ? eigentuemerwechselItem.hausnrzusatz
                    : ""
                }`,
        },
        {
          rowTitle: "PLZ",
          rowInfo: "",
        },
        {
          rowTitle: "Ort",
          rowInfo: eigentuemerwechselItem?.ort || "",
        },
      ],
    },
    {
      title: "Bisheriger Eigentümer Detailinfo",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerName}`,
        },
        {
          rowTitle: "Vorname",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerSurname}`,
        },
        {
          rowTitle: "Straße",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerStrasse}`,
        },
        {
          rowTitle: "Hausnummer",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerHausnr}
          ${
            eigentuemerwechselItem?.previousOwnerHausnrzusatz
              ? eigentuemerwechselItem.previousOwnerHausnrzusatz
              : ""
          }`,
        },
        {
          rowTitle: "PLZ",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerPlz}`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerOrt}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerPhoneNumber}`,
        },
        {
          rowTitle: "E-Mail Adresse",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerEmail}`,
        },
      ],
    },
    {
      title: "Neuer Eigentümer Detailinfo",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${eigentuemerwechselItem?.newOwnerName}`,
        },
        {
          rowTitle: "Vorname",
          rowInfo: `${eigentuemerwechselItem?.newOwnerSurname}`,
        },
        {
          rowTitle: "Straße",
          rowInfo: `${eigentuemerwechselItem?.previousOwnerStrasse}`,
        },
        {
          rowTitle: "Hausnummer",
          rowInfo: `${eigentuemerwechselItem?.newOwnerHausnr}
          ${
            eigentuemerwechselItem?.newOwnerHausnrzusatz
              ? eigentuemerwechselItem.newOwnerHausnrzusatz
              : ""
          }`,
        },
        {
          rowTitle: "PLZ",
          rowInfo: `${eigentuemerwechselItem?.newOwnerPlz}`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${eigentuemerwechselItem?.newOwnerOrt}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: `${eigentuemerwechselItem?.newOwnerPhoneNumber}`,
        },
        {
          rowTitle: "E-Mail Adresse",
          rowInfo: `${eigentuemerwechselItem?.newOwnerEmail}`,
        },
        {
          rowTitle: "Personenanzahl",
          rowInfo: `${eigentuemerwechselItem?.newOwnerQuantityOfPeople}`,
        },
        {
          rowTitle: "Eigentümer seit",
          rowInfo: format(
            new Date(eigentuemerwechselItem?.newOwnerSince),
            "dd.MM.yyyy",
            {
              locale: de,
            }
          ),
        },
        {
          rowTitle: "Mülltonnenübertragung zum",
          rowInfo: format(
            new Date(eigentuemerwechselItem?.transferDate),
            "dd.MM.yyyy",
            {
              locale: de,
            }
          ),
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isDataLoading ? null : !eigentuemerwechselItem ? null : (
          <div></div>
        )}
        <div>
          {items.map((el, idx) => {
            return (
              <AdminDetailInfoItem
                items={el.items}
                title={el.title}
                key={idx}
              />
            );
          })}
        </div>
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminEigentuemerwechselById;
