import {
  IZusatzleistung,
  IZusatzleistungenResponseBody,
} from "../../store/slices/zusatzleistungen";
import { adminInstance, userInstance } from "../api";

const getZusatzleistungen = ({
  gemeindeNr,
  kassenzeichen,
  objId,
}: {
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objId: string | number;
}) => {
  return userInstance
    .get<IZusatzleistungenResponseBody[]>("/zusatzleistung", {
      params: {
        gemeindeNr,
        kassenzeichen,
        objId,
      },
    })
    .then((res) => res.data);
};
export const createZusatzleistung = ({
  gemeindeNr,
  kassenzeichen,
  objId,
  data,
  phoneNumber,
}: {
  gemeindeNr: string | number;
  kassenzeichen: string | number;
  objId: string | number;
  phoneNumber: string;
  data: { behid: number; service: string[] }[];
}) => {
  return userInstance
    .post<string>("/zusatzleistung", {
      gemeindeNr,
      kassenzeichen,
      objId,
      data,
      phoneNumber,
    })
    .then((res) => res.data);
};

const getAdminZusatzleistungen = ({
  direction,
  page,
  pageSize,
  searchValue,
  sort,
  signal,
  startDate,
  endDate,
  status,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
}) => {
  return adminInstance
    .get<{
      totalCount: number;
      data: IZusatzleistung[];
    }>("/admin/zusatzleistung", {
      params: {
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        startDate,
        endDate,
        status,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminZusatzleistungenById = (id: string) => {
  return adminInstance
    .get<IZusatzleistung>(`/admin/zusatzleistung/${id}`)
    .then((res) => res.data);
};

const updateStatusById = ({ id, status }: { id: string; status: string }) => {
  return adminInstance
    .put<IZusatzleistung>(`admin/zusatzleistung/${id}/status`, {
      status,
    })
    .then((res) => res.data);
};

const Zusatzleistung = {
  get: {
    getZusatzleistungen,
    getAdminZusatzleistungen,
    getAdminZusatzleistungenById,
  },
  post: {
    createZusatzleistung,
  },
  put: {
    updateStatusById,
  },
};

export default Zusatzleistung;
