import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { adminReducer } from "./slices/admin";
import { appReducer } from "./slices/app";
import { calendarReducer } from "./slices/calendar";
import { newsReducer } from "./slices/news";
import { schadstoffmobilReducer } from "./slices/schadstoffmobil";
import { sperrmuellReducer } from "./slices/sperrmuell";
import { tokenReducer } from "./slices/token";
import { userReducer } from "./slices/user";
import { behaelterverwaltungReducer } from "./slices/behaelterverwaltung";
import { reclamationReducer } from "./slices/reclamation";
import { mailTrackingReducer } from "./slices/mailTracking";
import { behaeltergemeinschaftReducer } from "./slices/behaeltergemeinschaft";
import { eigenkompostiererReducer } from "./slices/eigenkompostierer";
import { leerungsdatenEinsichtReducer } from "./slices/leerungsdatenEinsicht";
import { objectVerwaltungReducer } from "./slices/objectVerwaltung";
import { sepaReducer } from "./slices/sepa";
import { eigentuemerwechselReducer } from "./slices/eigentuemerwechsel";
import { zusatzleistungReducer } from "./slices/zusatzleistungen";
import { stammdatenReducer } from "./slices/stammdaten";

export const store = configureStore({
  reducer: {
    app: appReducer,
    admin: adminReducer,
    calendar: calendarReducer,
    user: userReducer,
    schadstoffmobil: schadstoffmobilReducer,
    sperrmuell: sperrmuellReducer,
    news: newsReducer,
    token: tokenReducer,
    behaelterverwaltung: behaelterverwaltungReducer,
    reclamation: reclamationReducer,
    mailTracking: mailTrackingReducer,
    behaeltergemeinschaft: behaeltergemeinschaftReducer,
    eigenkompostierer: eigenkompostiererReducer,
    leerungsdatenEinsicht: leerungsdatenEinsichtReducer,
    objectVerwaltung: objectVerwaltungReducer,
    sepa: sepaReducer,
    eigentuemerwechsel: eigentuemerwechselReducer,
    zusatzleistung: zusatzleistungReducer,
    stammdaten: stammdatenReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
