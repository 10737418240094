import React, { FC } from "react";
import {
  Grid,
  GridColumn,
  GridSortChangeEvent,
  GridRowProps,
  GridCellProps,
  GridHeaderCellProps,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { SortDescriptor } from "@progress/kendo-data-query";
import style from "./style.module.scss";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import appConfig from "../../util/appConfig";
import { Button } from "../Button";
import SearchInput from "../../pages/AdminToken/components/ContentComponent/components/SearchInput";
import sortGroup from "../../assets/images/sortGroup.svg";
import customDownload from "../../assets/images/customDownload.svg";
import ascArrow from "../../assets/images/ascArrow.svg";
import descArrow from "../../assets/images/descArrow.svg";
import { TablePager } from "../TablePager";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import { de } from "date-fns/locale";

registerLocale("de", de);

interface IData {
  data: any;
  excelExportData?: any;
  items: {
    field: string;
    title: string;
    width?: string;
    headerCell?: any;
    cell?: any;
  }[];
  excelExportItem?: {
    field: string;
    title: string;
    width?: string;
    headerCell?: any;
    cell?: any;
  }[];
  itemsForPdf?: {
    field: string;
    title: string;
    width?: string;
    headerCell?: any;
    cell?: any;
  }[];
  firstItem?: any;
  lastItem?: any;
  sortable?: boolean;
  sort?: SortDescriptor[];
  onSortChange?: any;
  withExcelExport?: boolean;
  withPdfExport?: boolean;
  searchInputValue?: string;
  onSearchInputValueChange?: (str: string) => void;
  withPager?: boolean;
  pageSize?: number;
  page?: number;
  pageChangeHandler?: (page: number) => void;
  pageSizeHandler?: (size: any) => void;
  itemsCount?: number;
  withDatePicker?: boolean;
  onDateChange?: (date: [Date | null, Date | null]) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  onExcelExportClickHandler?: () => void;
  customHeight?: string;
  selectorOptions?: {
    placeholder: string;
    isActive: boolean;
    defaultValue?: {
      label: string;
      value: string;
    };
    selectOptions: { label: string; value: string }[];
    value: { value: string; label: string }[];
    onChange?: (val: string[]) => void;
  }[];
}

export const TableGrid: FC<IData> = ({
  data,
  excelExportData = [],
  excelExportItem = [],
  items,
  firstItem,
  lastItem,
  onSortChange,
  sort,
  sortable = false,
  withExcelExport,
  withPdfExport,
  onSearchInputValueChange,
  searchInputValue,
  page,
  pageChangeHandler,
  pageSize,
  pageSizeHandler,
  withPager = false,
  itemsCount,
  // withSelect,
  // selectDefaultValue,
  // selectOptions,
  // setValue,
  // value,
  withDatePicker,
  endDate,
  onDateChange,
  startDate,
  onExcelExportClickHandler,
  // withServiceTypeSelect = false,
  // selectDefaultServiceTypeValue,
  // selectServiceTypeOptions,
  // setServiceTypeValue,
  // serviceTypeSelectValue,
  itemsForPdf,
  // withLeistungsType,
  // setLeistungsartValue,
  // selectLestungsartTypeOptions,
  // leistungsartTypeSelectValue,
  // selectDefaultLestungsartTypeValue,
  // abfallartTypeSelectValue,
  // selectAbfallartTypeOptions,
  // setAbfallartValue,
  // withAbfallart,
  // behaeltergemeinschaftTypeSelectValue,
  // selectBehaeltergemeinschaftTypeOptions,
  // setBehaeltergemeinschaftTypeValue,
  // withBehaeltergemeinschaftType,
  customHeight,
  selectorOptions,
}) => {
  let _pdfExport: any;

  const exportPDF = () => {
    _pdfExport.save();
  };

  let _export: any;

  const exportExcel = () => {
    if (onExcelExportClickHandler) {
      onExcelExportClickHandler();
    }
    _export.save();
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {withExcelExport ||
      withPdfExport ||
      typeof searchInputValue === "string" ||
      onSearchInputValueChange ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            {typeof searchInputValue === "string" &&
            onSearchInputValueChange ? (
              // <div>
              <SearchInput
                changeHandler={onSearchInputValueChange}
                value={searchInputValue}
                marginBottom="0"
              />
            ) : // </div>
            null}
            {withDatePicker && onDateChange ? (
              <div
                style={{
                  width: "210px",
                }}
              >
                <DatePicker
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  className={style.customInput}
                  locale={"de"}
                  dateFormat="dd/MM/yyyy"
                  selectsRange
                  wrapperClassName={style.customInputWrapper}
                  isClearable
                />
              </div>
            ) : null}
            {selectorOptions?.length
              ? selectorOptions.map((el, idx) => {
                  return (
                    <Select
                      key={idx}
                      closeMenuOnSelect={false}
                      defaultValue={el.defaultValue}
                      isMulti
                      options={el.selectOptions}
                      value={el.value}
                      onChange={(i) => {
                        if (el.onChange) {
                          el.onChange(i.map((el) => el.value));
                        }
                      }}
                      styles={{
                        container: (baseStyles, state) => {
                          return {
                            ...baseStyles,
                            minWidth: "300px",
                            // height: "32px !important",
                          };
                        },
                        control: (baseStyles, state) => {
                          return {
                            ...baseStyles,
                            height: "32px !important",
                          };
                        },
                        multiValueLabel: (baseStyles, state) => {
                          return {
                            ...baseStyles,
                            fontFamily: "Nunito",
                          };
                        },
                        menuList: (baseStyles, state) => {
                          return {
                            ...baseStyles,
                            fontFamily: "Nunito",
                          };
                        },
                        placeholder: (baseStyles, state) => {
                          return {
                            ...baseStyles,
                            fontFamily: "Nunito",
                            fontSize: "14px",
                            color: "#353b3f",
                            fontWeight: "500",
                          };
                        },
                      }}
                      // styles={colourStyles}
                      placeholder={el.placeholder}
                    />
                  );
                })
              : null}
          </div>
          {withExcelExport || withPdfExport ? (
            <div className={style.btnWrapper}>
              {withExcelExport ? (
                <Button
                  text="Excel-Export"
                  width="150"
                  onClick={exportExcel}
                  backgroundColor="#008BD2"
                  textColor="#fff"
                  marginRight="20"
                  height="32"
                  customImg={customDownload}
                />
              ) : null}
              {withPdfExport ? (
                <Button
                  text="PDF-Export"
                  width="150"
                  backgroundColor="#008BD2"
                  onClick={exportPDF}
                  textColor="#fff"
                  height="32"
                  customImg={customDownload}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {/* <ExcelExport
        data={excelExportData ? excelExportData : data}
        ref={(exporter) => {
          _export = exporter;
        }}
      > */}
        <Grid
          data={data}
          style={{
            width: "100%",
            height: customHeight
              ? customHeight
              : `calc(100vh - ${
                  withPager
                    ? // ? "253px"
                      "315px"
                    : withExcelExport ||
                      withPdfExport ||
                      typeof searchInputValue === "string" ||
                      onSearchInputValueChange
                    ? "206px"
                    : "220px"
                })`,
          }}
          sortable={sortable}
          sort={sort}
          scrollable={"scrollable"}
          onSortChange={(e: GridSortChangeEvent) => {
            if (onSortChange) {
              onSortChange(e.sort);
            }
          }}
          data-qwe="qwe"
          className={style.qwe}
          rowRender={(row, props: GridRowProps) => {
            return (
              <tr
                aria-rowindex={props.ariaRowIndex}
                data-is-selected={props.dataItem.isIncluded ? "selected" : ""}
                data-env={appConfig.logo}
                className="k-table-row k-master-row"
                role="row"
                data-grid-row-index={props.dataIndex}
                style={{
                  backgroundColor:
                    props.dataItem.requestType === "greyList"
                      ? "#24ee2419"
                      : props.dataItem.isIncluded
                      ? `blue !important`
                      : ((props.dataItem?.status === "accepted" ||
                          props.dataItem?.status === "Fertig") &&
                          appConfig.logo === "calw") ||
                        props.dataItem?.selected
                      ? "rgba(36, 238, 36, 0.3)"
                      : (props.dataItem?.status === "declined" ||
                          props.dataItem?.status === "Storniert") &&
                        appConfig.logo === "calw"
                      ? "rgba(243, 58, 38, 0.3)"
                      : "",
                }}
              >
                {props.children}
              </tr>
            );
            // return row
          }}
        >
          {firstItem ? firstItem : null}
          <GridNoRecords>Keine Einträge vorhanden</GridNoRecords>
          {items.map((i, idx) => {
            return (
              <GridColumn
                field={i.field}
                title={i.title}
                key={`${i.title}${idx}`}
                width={i.width ? i.width : ""}
                headerClassName={style.titleText}
                className={style.itemText}
                headerCell={(props: GridHeaderCellProps) => {
                  const generateArrow = () => {
                    switch (true) {
                      case sort?.length &&
                        sort[0].field === props.field &&
                        sort[0].dir === "asc": {
                        return ascArrow;
                      }
                      case sort?.length &&
                        sort[0].field === props.field &&
                        sort[0].dir === "desc": {
                        return descArrow;
                      }
                      default: {
                        return sortGroup;
                      }
                    }
                  };
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={props.field || Math.floor(Math.random() * 1000000)}
                      onClick={() => {
                        const generateDirection = () => {
                          switch (true) {
                            case !sort?.length: {
                              return "asc";
                            }
                            case sort?.length && sort[0].dir === "asc": {
                              return "desc";
                            }
                            case sort?.length && sort[0].dir === "desc": {
                              return undefined;
                            }
                            case sort?.length && sort[0].dir === undefined: {
                              return "asc";
                            }
                            default: {
                              return undefined;
                            }
                          }
                        };
                        onSortChange([
                          {
                            field: props.field,
                            dir: generateDirection(),
                          },
                        ]);
                      }}
                    >
                      <span
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        {props.title}
                      </span>{" "}
                      <img
                        src={generateArrow()}
                        alt="sort"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  );
                }}
                cell={
                  i.cell
                    ? (props: GridCellProps) => {
                        return i.cell(props);
                      }
                    : undefined
                }
              />
            );
          })}
          {lastItem ? lastItem : null}
        </Grid>
      {/* </ExcelExport> */}
      <ExcelExport
        data={excelExportData}
        ref={(exporter) => {
          _export = exporter;
        }}
      >
        <Grid
          data={excelExportData }
          style={{
            display: 'none',
            // width: "100%",
            // height: customHeight
            //   ? customHeight
            //   : `calc(100vh - ${
            //       withPager
            //         ? // ? "253px"
            //           "315px"
            //         : withExcelExport ||
            //           withPdfExport ||
            //           typeof searchInputValue === "string" ||
            //           onSearchInputValueChange
            //         ? "206px"
            //         : "220px"
            //     })`,
          }}
          sortable={sortable}
          sort={sort}
          scrollable={"scrollable"}
          // onSortChange={(e: GridSortChangeEvent) => {
          //   if (onSortChange) {
          //     onSortChange(e.sort);
          //   }
          // }}
          data-qwe="qq"
          // className={style.qwe}
          // rowRender={(row, props: GridRowProps) => {
          //   return (
          //     <tr
          //       aria-rowindex={props.ariaRowIndex}
          //       data-is-selected={props.dataItem.isIncluded ? "selected" : ""}
          //       data-env={appConfig.logo}
          //       className="k-table-row k-master-row"
          //       role="row"
          //       data-grid-row-index={props.dataIndex}
          //       style={{
          //         backgroundColor:
          //           props.dataItem.requestType === "greyList"
          //             ? "#24ee2419"
          //             : props.dataItem.isIncluded
          //             ? `blue !important`
          //             : ((props.dataItem?.status === "accepted" ||
          //                 props.dataItem?.status === "Fertig") &&
          //                 appConfig.logo === "calw") ||
          //               props.dataItem?.selected
          //             ? "rgba(36, 238, 36, 0.3)"
          //             : (props.dataItem?.status === "declined" ||
          //                 props.dataItem?.status === "Storniert") &&
          //               appConfig.logo === "calw"
          //             ? "rgba(243, 58, 38, 0.3)"
          //             : "",
          //       }}
          //     >
          //       {props.children}
          //     </tr>
          //   );
          //   // return row
          // }}
        >
          {firstItem ? firstItem : null}
          <GridNoRecords>Keine Einträge vorhanden</GridNoRecords>
          {(excelExportItem.length ? excelExportItem : []).map((i, idx) => {
            return (
              <GridColumn
                field={i.field}
                title={i.title}
                key={`${i.title}${idx}`}
                width={i.width ? i.width : ""}
                headerClassName={style.titleText}
                className={style.itemText}
                headerCell={(props: GridHeaderCellProps) => {
                  const generateArrow = () => {
                    switch (true) {
                      case sort?.length &&
                        sort[0].field === props.field &&
                        sort[0].dir === "asc": {
                        return ascArrow;
                      }
                      case sort?.length &&
                        sort[0].field === props.field &&
                        sort[0].dir === "desc": {
                        return descArrow;
                      }
                      default: {
                        return sortGroup;
                      }
                    }
                  };
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={props.field || Math.floor(Math.random() * 1000000)}
                      onClick={() => {
                        const generateDirection = () => {
                          switch (true) {
                            case !sort?.length: {
                              return "asc";
                            }
                            case sort?.length && sort[0].dir === "asc": {
                              return "desc";
                            }
                            case sort?.length && sort[0].dir === "desc": {
                              return undefined;
                            }
                            case sort?.length && sort[0].dir === undefined: {
                              return "asc";
                            }
                            default: {
                              return undefined;
                            }
                          }
                        };
                        onSortChange([
                          {
                            field: props.field,
                            dir: generateDirection(),
                          },
                        ]);
                      }}
                    >
                      <span
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        {props.title}
                      </span>{" "}
                      <img
                        src={generateArrow()}
                        alt="sort"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  );
                }}
                cell={
                  i.cell
                    ? (props: GridCellProps) => {
                        return i.cell(props);
                      }
                    : undefined
                }
              />
            );
          })}
          {lastItem ? lastItem : null}
        </Grid>
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {
          <Grid data={data}>
            {(itemsForPdf?.length ? itemsForPdf : items).map((i, idx) => {
              return (
                <GridColumn
                  field={i.field}
                  title={i.title}
                  key={`${idx}${i.title}`}
                  width={i.width ? i.width : ""}
                  headerClassName={style.titleText}
                  className={style.itemText}
                  cell={
                    i.cell
                      ? (props: GridCellProps) => {
                          return i.cell(props);
                        }
                      : undefined
                  }
                />
              );
            })}
          </Grid>
        }
      </GridPDFExport>
      {withPager ? (
        <TablePager
          totalCount={itemsCount}
          itemsPerPage={pageSize}
          page={page}
          setPage={pageChangeHandler}
          itemsPerPageHandler={pageSizeHandler}
          totalPages={Math.ceil(itemsCount! / pageSize!)}
        />
      ) : null}
    </div>
  );
};
