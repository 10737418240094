import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";

export interface IReclamationResponseBody {
  id: string;
  email: string;
  userName: string;
  userSurname: string;
  date: string;
  type: string;
  place: string;
  street: string;
  homeNumber: string;
  hausNrZusatz: string;
  message: string;
  phoneNumber: string;
  createdAt: string;
  status?: string;
  reclamationReason?: string;
  history?: {
    id: string;
    createdAt: string;
    status: string;
    email: string;
    firstName?: string;
    lastName?: string;
  }[];
  postCode?: string;
}
export interface IReclamation {
  client: {
    places: {
      id: string;
      gemeindeBezeichnung: string;
      gemeindePostleitzahl: number;
      gemeindeNummer: number;
    }[];
    cityParts: {
      id: string;
      orteilBezeichnung: string;
      ortsteilNummer: number;
      ortsteilBezirk: string;
      gemeindeNummer: string;
    }[];
    streets: {
      id: string;
      gemeindeNummer: number;
      strasseBezeichnung: string;
      ortsteilNummer?: string;
    }[];
  };
  admin: {
    itemsCount: number;
    reclamationData: IReclamationResponseBody[];
    reclamationItem: IReclamationResponseBody | null;
    isReclamationItemDataLoading: boolean;
  };
}

const initialState: IReclamation = {
  client: {
    places: [],
    streets: [],
    cityParts: [],
  },
  admin: {
    itemsCount: 0,
    reclamationData: [],
    isReclamationItemDataLoading: false,
    reclamationItem: null,
  },
};

export const getLraReclamationStreetsAndPlaces = createAsyncThunk(
  "reclamation/client/getLraReclamationStreetsAndPlaces",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.reclamation.get.getReclamationDataLra();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getCalwReclamationStreetsAndPlaces = createAsyncThunk(
  "reclamation/client/getCalwReclamationStreetsAndPlaces",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data = await API.reclamation.get.getReclamationDataCalw();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const createLraReclamation = createAsyncThunk(
  "reclamation/client/createLraReclamation",
  async (
    {
      date,
      homeNumber,
      hausNrZusatz,
      place,
      reasons,
      reclamationReason,
      street,
      type,
      message,
      email,
      phoneNumber,
      userName,
      userSurname,
      solution,
      postCode,
    }: {
      place: string;
      street: string;
      homeNumber: string;
      hausNrZusatz: string;
      reclamationReason: string;
      type: string;
      date: string;
      message?: string;
      reasons: string[];
      phoneNumber: string;
      email: string;
      userName: string;
      userSurname: string;
      solution?: string;
      postCode?: string;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));
      await API.reclamation.post.createReclamation({
        date,
        homeNumber,
        place,
        reclamationReason,
        street,
        type,
        message,
        reasons,
        email,
        hausNrZusatz,
        phoneNumber,
        userName,
        userSurname,
        solution,
        postCode,
      });
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getAdminReclamation = createAsyncThunk(
  "reclamation/admin/getAdminReclamation",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
      status,
      type,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null;
      endDate?: Date | null;
      status?: string[];
      signal: AbortSignal;
      type: string[];
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data = API.reclamation.get.getAdminReclamation({
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        signal,
        endDate,
        startDate,
        status,
        type,
      });

      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const changeReclamationItemsStatuses = createAsyncThunk(
  "reclamation/admin/changeReclamationItemsStatuses",
  async ({ ids, status }: { ids: string[]; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));

      const data = await API.reclamation.put.changeItemsStatuses({
        ids,
        status,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getAdminReclamationItemById = createAsyncThunk(
  "reclamation/admin/getAdminReclamationItemById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.reclamation.get.getAdminReclamationItemById(id);

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);
export const updateStatusById = createAsyncThunk(
  "reclamation/admin/updateStatusById",
  async ({ id, status }: { id: string; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.reclamation.put.updateStatusById({ id, status });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const reclamationSlice = createSlice({
  name: "reclamation",
  initialState,
  reducers: {
    clearAdminReclamation: (state) => {
      state.admin.itemsCount = 0;
      state.admin.reclamationData = [];
    },
    clearAdminReclamationItem: (state) => {
      state.admin.reclamationItem = null;
      state.admin.isReclamationItemDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLraReclamationStreetsAndPlaces.pending, (state) => {});
    builder.addCase(
      getLraReclamationStreetsAndPlaces.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.client.places = action.payload.places;
          state.client.streets = action.payload.streets;
        }
      }
    );
    builder.addCase(getLraReclamationStreetsAndPlaces.rejected, (state) => {});
    builder.addCase(getCalwReclamationStreetsAndPlaces.pending, (state) => {});
    builder.addCase(
      getCalwReclamationStreetsAndPlaces.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.client.cityParts = action.payload.cityParts;
          state.client.streets = action.payload.streets as any;
        }
      }
    );
    builder.addCase(getCalwReclamationStreetsAndPlaces.rejected, (state) => {});
    builder.addCase(getAdminReclamation.pending, (state) => {});
    builder.addCase(getAdminReclamation.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.itemsCount = action.payload.totalCount;
        state.admin.reclamationData = [...action.payload.data];
      }
    });
    builder.addCase(getAdminReclamation.rejected, (state) => {});
    builder.addCase(changeReclamationItemsStatuses.pending, (state) => {});
    builder.addCase(
      changeReclamationItemsStatuses.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.reclamationData = action.payload;
        }
      }
    );
    builder.addCase(changeReclamationItemsStatuses.rejected, (state) => {});
    builder.addCase(getAdminReclamationItemById.pending, (state) => {
      state.admin.isReclamationItemDataLoading = true;
    });
    builder.addCase(getAdminReclamationItemById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.reclamationItem = action.payload;
      }
      state.admin.isReclamationItemDataLoading = false;
    });
    builder.addCase(getAdminReclamationItemById.rejected, (state) => {
      state.admin.isReclamationItemDataLoading = false;
    });
    builder.addCase(updateStatusById.pending, (state) => {
      state.admin.isReclamationItemDataLoading = true;
    });
    builder.addCase(updateStatusById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.reclamationItem = action.payload;
      }
      state.admin.isReclamationItemDataLoading = false;
    });
    builder.addCase(updateStatusById.rejected, (state) => {
      state.admin.isReclamationItemDataLoading = false;
    });
  },
});

export const { clearAdminReclamation, clearAdminReclamationItem } =
  reclamationSlice.actions;

export const reclamationReducer = reclamationSlice.reducer;
