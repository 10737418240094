import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "./components";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "./store";
import appConfig from "./util/appConfig";
import "react-toastify/dist/ReactToastify.css";
import {
  clearErrorMessage,
  clearSuccessMessage,
  getPortalData,
  setIsMobile,
  setIsToasterError,
  setIsToasterSuccess,
} from "./store/slices/app";
import MainContent from "./content/MainContent";
import AdminContent from "./content/AdminContent";
import "@progress/kendo-theme-default/dist/all.css";
import useWindowWidth from "./hooks/useWindowWidth";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resized = useWindowWidth();
  const isLoading = useAppSelector((state) => state.app.client.isLoading);
  const { isToasterError, errorMessage, isToasterSuccess, successMessage } =
    useAppSelector((state) => state.app.common);
  const { isBurgerMenuOpen, isConfirmationPopupActive } = useAppSelector((state) => state.app.client);

  const [isAdminPage, setIsAdminPage] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(true);
  const location = useLocation();

  const toastErrorHandler = () => {
    if (errorMessage === "Request canceled") {
      return;
    }
    toast.error(errorMessage || "Etwas ist schief gelaufen", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      icon: false,
      theme: "colored",
    });
  };
  const toastSuccessHandler = () => {
    toast.success(successMessage || "OK", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      icon: false,
      theme: "colored",
    });
  };

  useEffect(() => {
    const helper = document.querySelector<HTMLElement>("#helperWrapper");
    const platform = window.navigator.platform;
    if (isLoading || isBurgerMenuOpen || isConfirmationPopupActive) {
      document.body.style.overflow = "hidden";
      if (helper && platform.includes("Win")) {
        helper.style.paddingRight = "17px";
      }
    }

    if (!isLoading && !isBurgerMenuOpen && !isConfirmationPopupActive) {
      document.body.style.overflow = "inherit";
      if (helper && platform.includes("Win")) {
        helper.style.paddingRight = "0";
      }
    }
  }, [isBurgerMenuOpen, isLoading, isConfirmationPopupActive]);

  useEffect(() => {
    if (isToasterError) {
      toastErrorHandler();
      dispatch(setIsToasterError(false));
      dispatch(clearErrorMessage());
    }
    if (isToasterSuccess) {
      toastSuccessHandler();
      dispatch(setIsToasterSuccess(false));
      dispatch(clearSuccessMessage());
    }
  }, [isToasterError, isToasterSuccess]);

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = `./${appConfig.logo}Favicon.ico`;
    document.getElementsByTagName("head")[0].appendChild(link);
    let title = appConfig.title || "";

    if (appConfig.logo === "ebwo") {
      title = `${appConfig.title} - Bürgerportal und Sperrmüllanmeldungen`;
    }
    document.title = title;

    if (appConfig.logo === "sensis") {
      const newLink = document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.href = "https://unpkg.com/leaflet@1.9.3/dist/leaflet.css";
      newLink.integrity = "sha256-kLaT2GOSpHechhsozzB+flnD+zUyjE2LlfWPgU04xyI=";
      newLink.crossOrigin = "";
      document.getElementsByTagName("head")[0].appendChild(newLink);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      setIsAdminPage(true);
    } else {
      setIsAdminPage(false);
      //TODO temp solution
      if (appConfig.logo === "viersen") {
        const login = searchParams.get("login");
        const pwd = searchParams.get("pass");
        const link =
          (login === "null" || !login) && (pwd === "null" || !pwd)
            ? "/login"
            : `/login?login=${login}&pass=${pwd}`;
        navigate(link);
      }
    }
    setIsLoaderActive(false);
  }, []);

  useEffect(() => {
    dispatch(getPortalData());
  }, []);

  // useEffect(() => {
  //   if (appConfig.recaptchaKey) {
  //     const cookieType = localStorage.getItem("cookie");
  //     if (cookieType) {
  //       dispatch(setCookie(cookieType));
  //     }
  //   }
  // }, []);

  useLayoutEffect(() => {
    const media = window.matchMedia("(max-width: 576px)");
    if (media.matches) {
      dispatch(setIsMobile(true));
    } else {
      dispatch(setIsMobile(false));
    }
  }, [resized]);

  if (isLoaderActive) {
    return <Loader />;
  }

  return (
    <>
      {!isAdminPage ? <MainContent /> : <AdminContent />}
      {isLoading ? <Loader /> : null}
      <ToastContainer />
      {/* {appConfig.recaptchaKey ? <CookieBanner /> : null} */}
    </>
  );
}

export default App;

/**
 * NOTE: [14.11.2022]
 * changed everywhere the awb to bk (AWB Bad Kreuznach: awb > bk) and renamed the environment awb to bk
 */
