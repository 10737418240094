import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";
import { setAdminLoader } from "./admin";

export interface IBehaeltergemeinschaftBase {
  ownerName: string;
  ownerSurname: string;
  ownerPostcode: string;
  ownerPlace?: string;
  ownerPlacePart?: string;
  ownerStreet: string;
  ownerHomeNumber: string;
  ownerHomeNumberZusatz?: string;
  ownerBookingCode: string;
  ownerTrashBinData: {
    bin: string;
    binNumber: string;
  }[];
  couserName: string;
  couserSurname: string;
  couserPostcode: string;
  couserPlace?: string;
  couserPlacePart?: string;
  couserStreet: string;
  couserHomeNumber: string;
  couserHomeNumberZusatz?: string;
  couserBookingCode?: string;
  type: string;
  email?: string;
  phoneNumber?: string;
}

export interface IBehaeltergemeinschaftResponseBody
  extends IBehaeltergemeinschaftBase {
  id: string;
  createdAt: string;
  status: string;
  history?: {
    id: string;
    createdAt: string;
    status: string;
    email: string;
    firstName?: string;
    lastName?: string;
  }[];
}

export interface IBehaeltergemeinschaft {
  client: {
    places: {
      id: string;
      gemeindeBezeichnung: string;
      gemeindePostleitzahl: number;
      gemeindeNummer: number;
    }[];
    cityParts: {
      id: string;
      orteilBezeichnung: string;
      ortsteilNummer: number;
      ortsteilBezirk: string;
      gemeindeNummer: string;
    }[];
    streets: {
      id: string;
      gemeindeNummer: number;
      strasseBezeichnung: string;
      ortsteilNummer?: string;
    }[];
  };
  admin: {
    totalCount: number;
    behaeltergemeinschaftData: IBehaeltergemeinschaftResponseBody[];
    behaeltergemeinschaftItem: IBehaeltergemeinschaftResponseBody | null;
    isBehaeltergemeinschaftItemDataLoading: boolean;
  };
}

const initialState: IBehaeltergemeinschaft = {
  client: {
    cityParts: [],
    places: [],
    streets: [],
  },
  admin: {
    totalCount: 0,
    behaeltergemeinschaftData: [],
    behaeltergemeinschaftItem: null,
    isBehaeltergemeinschaftItemDataLoading: false,
  },
};

export const createItem = createAsyncThunk(
  "behaeltergemeinschaft/client/createItem",
  async (data: IBehaeltergemeinschaftBase, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      await API.behaeltergemeinschaft.post.create(data);
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const getAdminBehaeltergemeinschaft = createAsyncThunk(
  "behaeltergemeinschaft/admin/getAdminBehaeltergemeinschaft",
  async (
    {
      direction,
      page,
      pageSize,
      searchValue,
      sort,
      signal,
      endDate,
      startDate,
      status,
      type,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string;
      page?: number;
      pageSize?: number;
      startDate?: Date | null;
      endDate?: Date | null;
      status?: string[];
      type?: string[];
      signal: AbortSignal;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        API.behaeltergemeinschaft.get.getAdminBehaeltergemeinschaftData({
          direction,
          page,
          pageSize,
          searchValue,
          sort,
          signal,
          endDate,
          startDate,
          status,
          type,
        });

      return data;
    } catch (error: any) {
      if (error !== "Request canceled") {
        dispatch(setIsToasterError(true));
        dispatch(setErrorMessage(error?.response?.data?.message));
      }
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getAdminBehaeltergemeinschaftItemById = createAsyncThunk(
  "behaeltergemeinschaft/admin/getAdminBehaeltergemeinschaftItemById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.behaeltergemeinschaft.get.getAdminBehaeltergemeinschaftItemById(
          id
        );

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const updateAdminBehaeltergemeinschaftItemById = createAsyncThunk(
  "behaeltergemeinschaft/admin/updateAdminBehaeltergemeinschaftItemById",
  async ({ id, status }: { id: string; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data =
        await API.behaeltergemeinschaft.put.updateAdminBehaeltergemeinschaftItemById(
          {
            id,
            status,
          }
        );

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const changeItemsStatuses = createAsyncThunk(
  "behaeltergemeinschaft/admin/changeItemsStatuses",
  async ({ ids, status }: { ids: string[]; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));

      const data = await API.behaeltergemeinschaft.put.changeItemsStatuses({
        ids,
        status,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

export const getCalwBehaeltergemeinschaftStreetsAndPlaces = createAsyncThunk(
  "behaeltergemeinschaft/client/getCalwBehaeltergemeinschaftStreetsAndPlaces",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));
      const data =
        await API.behaeltergemeinschaft.get.getBehaeltergemeinschaftDataCalw();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const behaeltergemeinschaftSlice = createSlice({
  name: "behaeltergemeinschaft",
  initialState,
  reducers: {
    clearAdminBehaeltergemeinschaft: (state) => {
      state.admin.totalCount = 0;
      state.admin.behaeltergemeinschaftData = [];
    },
    clearAdminBehaeltergemeinschaftItem: (state) => {
      state.admin.behaeltergemeinschaftItem = null;
      state.admin.isBehaeltergemeinschaftItemDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminBehaeltergemeinschaft.pending, (state) => {});
    builder.addCase(
      getAdminBehaeltergemeinschaft.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.totalCount = action.payload.totalCount;
          state.admin.behaeltergemeinschaftData = [...action.payload.data];
        }
      }
    );
    builder.addCase(getAdminBehaeltergemeinschaft.rejected, (state) => {});
    builder.addCase(getAdminBehaeltergemeinschaftItemById.pending, (state) => {
      state.admin.isBehaeltergemeinschaftItemDataLoading = true;
    });
    builder.addCase(
      getAdminBehaeltergemeinschaftItemById.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.behaeltergemeinschaftItem = action.payload;
        }
        state.admin.isBehaeltergemeinschaftItemDataLoading = false;
      }
    );
    builder.addCase(getAdminBehaeltergemeinschaftItemById.rejected, (state) => {
      state.admin.isBehaeltergemeinschaftItemDataLoading = false;
    });
    builder.addCase(
      updateAdminBehaeltergemeinschaftItemById.pending,
      (state) => {}
    );
    builder.addCase(
      updateAdminBehaeltergemeinschaftItemById.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.behaeltergemeinschaftItem = action.payload;
        }
      }
    );
    builder.addCase(
      updateAdminBehaeltergemeinschaftItemById.rejected,
      (state) => {}
    );
    builder.addCase(changeItemsStatuses.pending, (state) => {});
    builder.addCase(changeItemsStatuses.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.behaeltergemeinschaftData = action.payload;
      }
    });
    builder.addCase(changeItemsStatuses.rejected, (state) => {});
    builder.addCase(
      getCalwBehaeltergemeinschaftStreetsAndPlaces.pending,
      (state) => {}
    );
    builder.addCase(
      getCalwBehaeltergemeinschaftStreetsAndPlaces.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.client.cityParts = action.payload.cityParts;
          state.client.streets = action.payload.streets as any;
        }
      }
    );
    builder.addCase(
      getCalwBehaeltergemeinschaftStreetsAndPlaces.rejected,
      (state) => {}
    );
  },
});

export const {
  clearAdminBehaeltergemeinschaft,
  clearAdminBehaeltergemeinschaftItem,
} = behaeltergemeinschaftSlice.actions;

export const behaeltergemeinschaftReducer = behaeltergemeinschaftSlice.reducer;
