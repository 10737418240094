import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { SortDescriptor } from "@progress/kendo-data-query";
import { statuses } from "../AdminSperrmuelanmeldungen";
import { GridColumn } from "@progress/kendo-react-grid";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import appConfig from "../../util/appConfig";
import { format } from "date-fns";
import {
  clearAdminEigentuemerwechselData,
  getAdminEigentuemerwechsel,
} from "../../store/slices/eigentuemerwechsel";

const AdminEigentuemerwechsel = () => {
  const dispatch = useAppDispatch();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { data, itemsCount } = useAppSelector(
    (state) => state.eigentuemerwechsel.admin
  );
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminEigentuemerwechsel({
        signal,
        direction: sort[0]?.dir,
        endDate,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        startDate,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged]);

  useEffect(() => {
    return () => {
      dispatch(clearAdminEigentuemerwechselData());
    };
  }, []);

  return (
    <div>
      <AdminHeader pageTitle={"Eigentümerwechsel"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={data.map((el) => {
            return {
              ...el,
              // name: `${el.username} ${
              //   el.userSurname ? ", " + el.userSurname : ""
              // }`,
              // status: statuses[el.status],
              previousOwnerFullName: `${el.previousOwnerName} ${el.previousOwnerSurname}`,
              newOwnerFullName: `${el.newOwnerName} ${el.newOwnerSurname}`,
              createdAt: format(new Date(el.createdAt), "dd.MM.yyyy HH:mm"),
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          withExcelExport={true}
          items={[
            { field: "createdAt", title: "Datum" },
            { field: "previousOwnerFullName", title: "Vorheriger Besitzer" },
            { field: "newOwnerFullName", title: "Neuer Besitzer" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminEigentuemerwechsel;
