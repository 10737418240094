import React, { CSSProperties, FC } from "react";
import style from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import { logout } from "../../store/slices/user";
import appConfig from "../../util/appConfig";

interface IHeaderSelect {
  customStyle?: CSSProperties;
}

const links = [
  {
    link: "/kassenzeichen-verwaltung",
    text: "Kassenzeichen Verwaltung",
    isActive: ["lra", "calw"].includes(appConfig.logo),
  },
];

export const HeaderSelect: FC<IHeaderSelect> = ({ customStyle }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    objectIdUser: { vorname, nachname },
    defaultUserObject: { hausnr, hausnrzusatz, strassebez, ortsteilbez, plz },
  } = useAppSelector((state) => state.user);
  return (
    <div>
      <Popup
        trigger={
          <button
            type="button"
            style={{
              border: "none",
              display: "flex",
            }}
          >
            <div className={style.valueContainer}>
              <div className={style.name}>
                {vorname ? vorname + ", ": ""}{nachname ? nachname : ""}
              </div>
              <div className={style.address}>
                {strassebez ? strassebez : ""}, {hausnr ? hausnr : ""}{" "}
                {hausnrzusatz ? hausnrzusatz : ""}
              </div>
              <div className={style.address}>
                {plz ? plz : ""}, {ortsteilbez ? ortsteilbez : ""}
              </div>
            </div>
          </button>
        }
        position={"bottom center"}
        on={["hover", "click"]}
        arrow={false}
        className={style.ewq}
        open={true}
      >
        <>
          <div className={style.linksWrapper}>
            {links
              .filter((el) => el.isActive)
              .map((el) => {
                return (
                  <Link to={el.link} className={style.link} key={el.link}>
                    {el.text}
                  </Link>
                );
              })}
          </div>
          <div
            className={style.footerDivider}
            style={{
              margin: "10px 0",
            }}
          />
          <div
            className={style.link}
            onClick={() => {
              dispatch(
                logout({
                  navigate: () => {
                    navigate("/");
                  },
                })
              );
            }}
          >
            Ausloggen
          </div>
        </>
      </Popup>
    </div>
  );
};
