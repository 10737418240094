import { userInstance } from "../api";

const getObjectVerwaltungData = () => {
  return userInstance
    .get(`/object-verwaltung/data`)
    .then((res) => res.data);
};

const ObjectVerwaltung = {
  get: {
    getObjectVerwaltungData,
  },
};

export default ObjectVerwaltung;
