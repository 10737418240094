import React, { useEffect } from "react";
import style from "./style.module.scss";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
} from "../../components";
import { setDataLoading } from "../../store/slices/admin";
import {
  clearAdminSepaItem,
  getAdminSepaItemById,
  updateStatusById,
} from "../../store/slices/sepa";
import { statuses } from "../AdminSperrmuelanmeldungen";
import transformHexToRgba from "../../util/transformHexToRgba";
import appConfig from "../../util/appConfig";

const payerTypes: { [key: string]: string } = {
  Eigentümer: "Eigentümer",
  abweichendenZahlungsempfänder: "Abweichender Rechnungsempfänger",
};

const AdminSepaById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isSepaDataLoading, sepaItem } = useAppSelector(
    (state) => state.sepa.admin
  );

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminSepaItemById(itemId));
    }
    return () => {
      dispatch(clearAdminSepaItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  const items = [
    {
      title: "Detailinformation",
      items: [
        {
          rowTitle: "Status",
          rowInfo: statuses[sepaItem?.status!],
        },
        {
          rowTitle: "Leistung",
          rowInfo: sepaItem?.payerType ? payerTypes[sepaItem?.payerType!] : "",
        },
        {
          rowTitle: "IBAN",
          rowInfo: sepaItem?.iban || "",
        },
        {
          rowTitle: "BIC",
          rowInfo: sepaItem?.bic || "",
        },
        {
          rowTitle: "Erstelungsdatum",
          rowInfo: sepaItem?.createdAt
            ? format(new Date(sepaItem?.createdAt), "dd.MM.yyyy", {
                locale: de,
              })
            : "",
          isLast: true,
        },
      ],
    },
    {
      title: "Benutzer Information",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${sepaItem?.userSurname} ${
            sepaItem?.username ? ", " + sepaItem?.username : ""
          }`,
        },
        {
          rowTitle: "PK-ID",
          rowInfo: `${sepaItem?.idNumber}`,
        },
        {
          rowTitle: "Adresse",
          rowInfo: `${sepaItem?.country ? sepaItem.country + ", " : ""}${
            sepaItem?.place
          }, ${sepaItem?.postCode}, ${sepaItem?.street}, ${
            sepaItem?.homeNumber
          } ${sepaItem?.homeNumberZusatz ? sepaItem?.homeNumberZusatz : ""}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: sepaItem?.phoneNumber ? "+" + sepaItem?.phoneNumber : "",
        },
        {
          rowTitle: "Kontaknummer",
          rowInfo: sepaItem?.contactPhoneNumber
            ? "+" + sepaItem?.contactPhoneNumber
            : "",
          isLast: true,
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isSepaDataLoading ? null : !sepaItem ? null : <div></div>}
        <div>
          {items.map((el, idx) => {
            return (
              <AdminDetailInfoItem
                items={el.items}
                title={el.title}
                key={idx}
              />
            );
          })}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              text="Akzeptieren"
              width="200"
              isOutlined
              outlineColor={
                sepaItem?.status === "accepted"
                  ? transformHexToRgba(appConfig.mainColor, "0.4")
                  : appConfig.mainColor
              }
              textColor={
                sepaItem?.status === "accepted"
                  ? transformHexToRgba(appConfig.mainColor, "0.4")
                  : appConfig.mainColor
              }
              disabled={sepaItem?.status === "accepted"}
              marginRight="20"
              onClick={() => {
                if (itemId) {
                  dispatch(
                    updateStatusById({
                      id: itemId,
                      status: "accepted",
                    })
                  );
                }
              }}
            />
            <Button
              text="Stornieren"
              width="200"
              backgroundColor="white"
              isOutlined
              outlineColor={
                sepaItem?.status === "declined"
                  ? transformHexToRgba("#ff0000", "0.4")
                  : "red"
              }
              textColor={
                sepaItem?.status === "declined"
                  ? transformHexToRgba("#ff0000", "0.4")
                  : "red"
              }
              disabled={sepaItem?.status === "declined"}
              marginRight="20"
              onClick={() => {
                if (itemId) {
                  dispatch(
                    updateStatusById({
                      id: itemId,
                      status: "declined",
                    })
                  );
                }
              }}
            />
          </div>
        </div>
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSepaById;
