import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import { SortDescriptor } from "@progress/kendo-data-query";
import { statuses } from "../AdminSperrmuelanmeldungen";
import { GridColumn } from "@progress/kendo-react-grid";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import appConfig from "../../util/appConfig";
import { format } from "date-fns";
import { clearAdminSepaData, getAdminSepa } from "../../store/slices/sepa";

const AdminSepa = () => {
  const dispatch = useAppDispatch();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { data, itemsCount } = useAppSelector((state) => state.sepa.admin);
  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [payerType, setPayerType] = useState<string[]>([]);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };
  const changePayerType = (st: string[]) => {
    setPayerType([...st]);
  };

  const generateSelectOptions = (type: string) => {
    switch (type) {
      case "lra": {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
          { label: statuses.declined, value: "declined" },
        ];
      }
      default: {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
        ];
      }
    }
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminSepa({
        payerType,
        signal,
        direction: sort[0]?.dir,
        endDate,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        startDate,
        status,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status, payerType]);

  const selectorOptions = [
    {
      placeholder: "Bitte Status wählen",
      isActive: ["lra"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateSelectOptions(appConfig.logo).filter(
        (el) => !status.includes(el.value)
      ),
      value: status.map((el) => {
        return {
          value: el,
          label: statuses[el],
        };
      }),
      onChange: changeStatuses,
    },
    {
      placeholder: "Bitte Leistung wählen",
      isActive: true,
      defaultValue: undefined,
      selectOptions: [
        {
          value: "Eigentümer",
          label: "Eigentümer",
        },
        {
          value: "abweichendenZahlungsempfänder",
          label: "Abweichenden Zahlungsempfänder",
        },
      ],
      value: payerType.map((el) => {
        return { value: el, label: el };
      }),
      onChange: changePayerType,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearAdminSepaData());
    };
  }, []);

  return (
    <div>
      <AdminHeader pageTitle={"SEPA Management"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={data.map((el) => {
            return {
              ...el,
              name: `${el.username} ${
                el.userSurname ? ", " + el.userSurname : ""
              }`,
              status: statuses[el.status],
              createdAt: format(new Date(el.createdAt), "dd.MM.yyyy HH:mm"),
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "createdAt", title: "Datum" },
            { field: "name", title: "Name" },
            { field: "street", title: "Straße" },
            { field: "postCode", title: "PLZ" },
            { field: "payerType", title: "Leistung" },
            { field: "status", title: "Status" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          selectorOptions={selectorOptions}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSepa;
