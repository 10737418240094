import { IReclamationResponseBody } from "../../store/slices/reclamation";
import { adminInstance, instance } from "../api";

const getAreas = () => {
  return instance
    .get<{ termin: string }[]>("/reclamation/area")
    .then((res) => res.data);
};

const getStreets = ({ area }: { area: string }) => {
  return instance
    .get<{ termin: string }[]>("/reclamation/dates", {
      params: {
        area,
      },
    })
    .then((res) => res.data);
};
const getDates = ({
  street,
  homeNumber,
  place,
  reclamationReason,
  type,
}: {
  place: string;
  street: string;
  homeNumber: string;
  reclamationReason: string;
  type: string;
}) => {
  return instance
    .get<{ termin: string }[]>("/reclamation/dates", {
      params: {
        street,
        homeNumber,
        place,
        reclamationReason,
        type,
      },
    })
    .then((res) => res.data);
};

const createReclamation = ({
  date,
  homeNumber,
  place,
  reclamationReason,
  street,
  type,
  message,
  reasons,
  email,
  hausNrZusatz,
  phoneNumber,
  userName,
  userSurname,
  solution,
  postCode,
}: {
  place: string;
  street: string;
  homeNumber: string;
  hausNrZusatz: string;
  reclamationReason: string;
  type: string;
  date: string;
  message?: string;
  reasons: string[];
  phoneNumber: string;
  email: string;
  userName: string;
  userSurname: string;
  solution?: string;
  postCode?: string;
}) => {
  return instance
    .post("/reclamation", {
      date,
      homeNumber,
      place,
      reclamationReason,
      street,
      type,
      message,
      reasons,
      email,
      phoneNumber,
      hausNrZusatz,
      userName,
      userSurname,
      solution,
      postCode,
    })
    .then((res) => res.data);
};

const getReclamationDataLra = () => {
  return instance
    .get<{
      places: {
        id: string;
        gemeindeBezeichnung: string;
        gemeindePostleitzahl: number;
        gemeindeNummer: number;
      }[];
      streets: {
        id: string;
        gemeindeNummer: number;
        strasseBezeichnung: string;
      }[];
    }>("/reclamation/data/lra")
    .then((res) => res.data);
};
const getReclamationDataCalw = () => {
  return instance
    .get<{
      cityParts: {
        id: string;
        orteilBezeichnung: string;
        ortsteilNummer: number;
        ortsteilBezirk: string;
        gemeindeNummer: string;
      }[];
      streets: {
        id: string;
        gemeindeNummer: number;
        strasseBezeichnung: string;
        ortsteilNummer: number;
      }[];
    }>("/reclamation/data/calw")
    .then((res) => res.data);
};

const getAdminReclamation = ({
  direction,
  page,
  pageSize,
  searchValue,
  sort,
  signal,
  startDate,
  endDate,
  status,
  type,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
  type?: string[];
}) => {
  return adminInstance
    .get<{
      totalCount: number;
      data: IReclamationResponseBody[];
    }>("/admin/reclamation", {
      params: {
        direction,
        page,
        pageSize,
        searchValue,
        sort,
        startDate,
        endDate,
        status,
        type,
      },
      signal,
    })
    .then((res) => res.data);
};

const changeItemsStatuses = ({
  ids,
  status,
}: {
  ids: string[];
  status: string;
}) => {
  return adminInstance
    .put<IReclamationResponseBody[]>(`/admin/reclamation/status`, {
      status,
      ids,
    })
    .then((res) => res.data);
};

const getAdminReclamationItemById = (id: string) => {
  return adminInstance
    .get<IReclamationResponseBody>(`/admin/reclamation/${id}`)
    .then((res) => res.data);
};

const updateStatusById = ({ id, status }: { id: string; status: string }) => {
  return adminInstance
    .put<IReclamationResponseBody>(`/admin/reclamation/${id}/status`, {
      status,
    })
    .then((res) => res.data);
};

const Reclamation = {
  get: {
    getAreas,
    getDates,
    getStreets,
    getReclamationDataLra,
    getAdminReclamation,
    getReclamationDataCalw,
    getAdminReclamationItemById,
  },
  post: {
    createReclamation,
  },
  put: {
    changeItemsStatuses,
    updateStatusById,
  },
};

export default Reclamation;
