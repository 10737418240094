import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setIsLoading, setIsToasterError } from "./app";
import API from "../../api/api";

export interface IObjectVerwaltung {
  adressart: string;
  objektid: number;
  kassenzeichen: number;
  strassebez: string;
  hausnr: number;
  hausnrzusatz: string | null;
  plz: string;
  bezirkbez: null | string;
  bezirknr: null | string | number;
  gemeindebez: string;
  gemeindenr: number;
  hausnrinfo: string;
  ortsteilbez: string;
  ortsteilnr: number;
  strassenr: number;
}

interface IOobjectVerwaltung {
  client: {
    data: IObjectVerwaltung[];
    isDataLoading: boolean;
  };
}

const initialState: IOobjectVerwaltung = {
  client: { data: [], isDataLoading: true },
};

export const getObjectVerwaltungData = createAsyncThunk(
  "sepa/client/getObjectVerwaltungData",
  async (_, { dispatch }) => {
    try {
      dispatch(setIsLoading(true));

      const data = await API.objectVerwaltung.get.getObjectVerwaltungData();

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

const objectVerwaltungSlice = createSlice({
  name: "sepa",
  initialState,
  reducers: {
    clearObjectVerwaltungData: (state) => {
      state.client.data = [];
      state.client.isDataLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getObjectVerwaltungData.pending, (state) => {
      state.client.isDataLoading = true;
    });
    builder.addCase(getObjectVerwaltungData.fulfilled, (state, action) => {
      state.client.isDataLoading = false;
      if (action.payload) {
        state.client.data = action.payload;
      }
    });
    builder.addCase(getObjectVerwaltungData.rejected, (state) => {
      state.client.isDataLoading = false;
    });
  },
});

export const { clearObjectVerwaltungData } = objectVerwaltungSlice.actions;

export const objectVerwaltungReducer = objectVerwaltungSlice.reducer;
